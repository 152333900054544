<template>
  <div class="block-wrapper">
    <a-form @submit.prevent="handleSubmit">
      <a-form-item label="ID">
        <a-input read-only :value="client.id" />
      </a-form-item>

      <a-form-item label="Статус">
        <a-checkbox v-model="client.is_active">
          Активный
        </a-checkbox>
      </a-form-item>

      <a-form-item label="Почта клиента">
        <a-input read-only :value="client.email" />
      </a-form-item>

      <a-form-item label="Имя">
        <a-input read-only :value="client.first_name" />
      </a-form-item>

      <a-form-item label="Фамилия">
        <a-input read-only :value="client.last_name" />
      </a-form-item>

      <a-form-item label="Дата создания">
        <a-input
          :value="new Date(client.created_at).toLocaleString('ru-RU')"
          read-only
        />
      </a-form-item>

      <a-form-item label="Дата последнего изменения">
        <a-input
          :value="new Date(client.updated_at).toLocaleString('ru-RU')"
          read-only
        />
      </a-form-item>

      <a-form-item :style="{ display: 'flex', justifyContent: 'flex-end' }">
        <a-button @click.prevent="$router.go(-1)">
          Назад
        </a-button>
        <a-button
          :style="{ marginLeft: '15px' }"
          type="primary"
          html-type="submit"
        >
          Сохранить
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { GET_CLIENT } from '@/store/types'
import { showNotification } from '@/utils'

export default {
  data() {
    return {
      client: {},
    }
  },

  async created() {
    this.getClient()
  },

  methods: {
    async getClient() {
      try {
        const client = await this.$store.dispatch(
          GET_CLIENT,
          this.$route.params.id,
        )

        this.client = client
      } catch (error) {
        showNotification(
          'Ошибка',
          error.response ? error.response.data.message : error.message,
          'error',
        )
      }
    },
  },
}
</script>
